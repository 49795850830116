import { z } from 'zod';

export const AddressSchema = z.object({
  address: z
    .object({
      value: z.object({
        label: z.string().trim().min(1, {
          message: 'form.required',
        }),
        coordinates: z.tuple([z.number(), z.number()], {
          required_error: 'form.required',
        }),
      }),
      options: z
        .object({
          label: z.string().trim().min(1, {
            message: 'form.required',
          }),
          coordinates: z.tuple([z.number(), z.number()], {
            required_error: 'form.required',
          }),
        })
        .array(),
    })
    // NOTE: hack to make sure the nested object is parsed by the superRefine function
    .strict()
    .catch((ctx) => ctx.input),
});

export type AddressSchemaType = z.infer<typeof AddressSchema>;

//Custom ZOD validation:
// const { value, options = [] } = address;
// if (!options.find(({label, coordinates}) => label === value?.label && coordinates === value.coordinates)) {
//   ctx.addIssue({
//     code: 'custom',
//     message: 'form.selectAddressFromOptions',
//     path: ['address'],
//   });
// }
